import React, { useEffect, useState } from 'react';
import axios from 'axios';
import tchLogo from '../assets/tch-logo-v.png';
import { Box, CircularProgress, Container, Grid, Skeleton, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const CheckStatusPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [statusText, setStatusText] = useState("We are checking your ID verification status. Please wait a moment.");
  const [userDetails, setUserDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const searchParams = new URLSearchParams(location.search);
  const email = decodeURIComponent(searchParams.get('email'));

  console.log('searching email: ', email)
  
  useEffect(() => {
    if (email) {
      fetchUserData(email);
    } else {
      setStatusText("Email address not found. Redirecting...");
      setTimeout(() => navigate('/tch/notfound'), 3000);
    }
  }, [email, navigate]);

  const fetchUserData = async (email) => {
    try {
      const params = {
        email: email
      }
      const response = await axios.post(`${process.env.REACT_APP_DATASYNC_URL}/descope/search`, params);
      const data = response.data;

      console.log('descope data: ', data)

      if (data) {
        setUserDetails({
          guid: data.userId,
          email: data.email,
          idVerified: data.customAttributes.idVerified,
          firstName: data.customAttributes.firstName,
          lastName: data.customAttributes.lastName,
          playerNumber: data.customAttributes.playerNumber,
          idReason: data.customAttributes.idReason
        });
      } else {
        setStatusText("Player information not found. Redirecting...");
        setTimeout(() => navigate('/tch/notfound'), 3000);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setStatusText("Error retrieving user data. Redirecting...");
      navigate('/tch/error', {
        state: {
          message: 'There was a problem retrieving your information, proceed to the front desk for assistance.'
        }
      });
    }
  };

  useEffect(() => {
    if (userDetails) {
      checkIdVerification(userDetails.idVerified);
    }
  }, [userDetails]);

  const checkIdVerification = (idVerified) => {
    let elapsedTime = 0;
    const intervalTime = 10000; // Check every 10 seconds
    const maxTime = 30000; // Stop checking after 30 seconds

    const intervalId = setInterval(async () => {
      const response = await axios.post(`${process.env.REACT_APP_DATASYNC_URL}/descope/search`, { email: userDetails.email });
      const playerNumber = response.data.customAttributes.playerNumber;

      if ((idVerified === 'APPROVED' || idVerified === 'SUSPECTED') && playerNumber) {
        setIsLoading(false);
        clearInterval(intervalId);
        navigate('/tch/confirmed', { state: { ...userDetails } });
      } else if (idVerified === 'FAILED' || idVerified === 'DENIED') {
        setIsLoading(false);
        clearInterval(intervalId);
        navigate('/tch/failed', { state: { ...userDetails } });
      } else {
        elapsedTime += intervalTime;
        if (elapsedTime >= maxTime) {
          clearInterval(intervalId);
          setStatusText("We couldn't verify your ID. Redirecting...");
          setTimeout(() => {
            setIsLoading(false);
            navigate('/tch/failed', { state: { ...userDetails } });
          }, 3000);
        } else {
          // Fetch the latest ID verification status
          try {
            idVerified = response.data.customAttributes.idVerified;
          } catch (error) {
            console.error('Error checking ID verification status:', error);
            navigate('/tch/error', {
              state: {
                message: 'There was a problem checking your ID verification status, proceed to the front desk for assistance.'
              }
            });
          }
        }
      }
    }, intervalTime);
  };

  return (
    <>
      <div className="page">
        <Container maxWidth="sm" sx={{ paddingY: "16px" }}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={9}>
              <div className="descope-form" data-testid="descope-component">
                <div
                  style={{
                    padding: "2rem 2rem",
                    backgroundColor: "#1e1e1e",
                    color: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  <img
                    style={{ width: "300px", marginBottom: "1rem" }}
                    src={tchLogo}
                    alt="TCH Logo"
                  />
                  <Typography mb={2}>
                    {email ? (
                      <>{statusText}</>
                    ) : (
                      <>
                        <>{statusText}</>
                      </>
                    )}
                  </Typography>
                  <Box sx={{ display: 'flex', 'justifyContent': 'center' }}>
                    {isLoading ? (
                      <CircularProgress 
                        sx={{
                          color: (theme) => theme.palette.grey[50],
                        }}
                        thickness={3}
                        size={60}
                      />
                    ) : null}
                  </Box>
                  <Typography variant="h6">
                    {email ? (<>{email}</>) : (
                      <>
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                          <Skeleton sx={{ backgroundColor: "#434343" }} width='80%' />
                        </Box>
                      </>
                    )}
                  </Typography>
                  <Typography variant="subtitle2" mb={1}>
                    Player Email Address
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default CheckStatusPage;