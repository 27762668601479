import { Descope, SignInFlow } from '@descope/react-sdk';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DescopeLogin from "../../Descope/DescopeLogin"
import { Container, Grid } from '@mui/material';

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (username === 'admin' && password === 'password') {
      alert('Login successful');
      navigate('/');
    } else {
      alert('Invalid credentials');
    }
  };

  const handleSuccess = (event) => {
    const play_domain =process.env.REACT_APP_PLAY_DOMAIN
    const domain = process.env.REACT_APP_DOMAIN
    const details = event.detail;
    const { sessionJwt, refreshJwt } = details;

    const tokenCookie = JSON.stringify({
      access_token: sessionJwt,
      id_token: sessionJwt,
      refresh_token: refreshJwt,
    });

    const encodedTokenCookie = createEncodedTokenCookie(tokenCookie);
    console.log(encodedTokenCookie);
    document.cookie = `token-storage=${encodedTokenCookie}; path=/; domain=.${domain};`;
    console.log(play_domain);
    console.log(domain);
    window.location.href = play_domain;
  };

  return (
    <div className='page'>
      <Container maxWidth="sm">
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={10} md={9}>
            <div className='descope-form' data-testid="descope-component">
              <DescopeLogin onSuccess={handleSuccess} onError={(e) => console.log('Could not log in')} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

function createEncodedTokenCookie(stringToken) {
  let cleanedString = stringToken.replace(/ /g, "%20")
                                .replace(/"/g, "%22")
                                .replace(/,/g, "%2C");
  return cleanedString;
}